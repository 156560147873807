import { ConfigProvider } from "antd";
import { CustomThemeProvider } from "customHooks/ThemeContext";
import React, { lazy } from "react";
import { ThemeProvider } from "styled-components";
import LoaderGif from "assets/newImages/LogoColor.svg"
import { ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

const AppRoutes = lazy(() => import('routes/Routes'));

function App() {
  return (
    <React.Suspense
      fallback={
        <div
          className="h-screen w-screen bg-white dark:bg-dark130 flex items-center justify-center"
        >
          <div className="block">
            <img src={LoaderGif} className="animate-fade w-[160px] h-auto block" alt="Loading..." />
          </div>
        </div>
      }
    >
      <CustomThemeProvider>
        <ThemeProvider
          theme={
            {
              primary: "#EA2328"
            }
          }>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#EA2328",
                colorPrimaryActive: "#EA2328",
              },
            }}
          >
            <AppRoutes />
            <ToastContainer />
          </ConfigProvider>
        </ThemeProvider>
      </CustomThemeProvider>
    </React.Suspense>
  );
}

export default App;
